import React, { useState, useEffect } from "react";
import crypto from "crypto";

import Day from "./Day";
import EmptyDay from "./EmptyDay";

import "./styles.scss";
import { Col } from "reactstrap";

import Loading from "components/Loading";

export default function Calendar(monthData) {
  const [loading, setLoading] = useState(false);

  const [daysMon, setDaysMon] = useState([]);

  useEffect(() => {
    whatDays(monthData);
  }, [monthData]);

  async function whatDays(monthData) {
    setLoading(true);
    try {
      const month = monthData.monthData;
      const firstDay = month[0].Day.name;
      const days = [];

      // O primeiro switch case irá observar o dia em que a semana começa e
      // preencher com objetos em branco os dias que antecedem. O segundo
      // Fará o mesmo, mas para os dias seguintes ao fim do mês.

      // A função crypto serve para gerar um código aleatório para que sirva
      // como a ID desse dia em branco. A ID é necessária no map feito para
      // preencher o calendário

      switch (firstDay) {
        case "Segunda":
          days.push(
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            ...month
          );
          break;
        case "Terça":
          days.push(
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            ...month
          );
          break;
        case "Quarta":
          days.push(
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            ...month
          );
          break;
        case "Quinta":
          days.push(
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            ...month
          );
          break;
        case "Sexta":
          days.push(
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            ...month
          );
          break;
        case "Sábado":
          days.push(
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            ...month
          );
          break;
        case "Domingo":
          days.push(...month);
          break;
        default:
          break;
      }

      const positionlastday = month[month.length - 1].Day.name;

      switch (positionlastday) {
        case "Segunda":
          days.push(
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            }
          );
          break;
        case "Terça":
          days.push(
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            }
          );
          break;
        case "Quarta":
          days.push(
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            }
          );
          break;
        case "Quinta":
          days.push(
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            }
          );
          break;
        case "Sexta":
          days.push({
            Day: "empty",
            idScheduling: crypto.randomBytes(6).toString("hex"),
          });
          break;
        case "Domingo":
          days.push(
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            },
            {
              Day: "empty",
              idScheduling: crypto.randomBytes(6).toString("hex"),
            }
          );
          break;
        default:
          break;
      }

      setDaysMon(days);
    } catch {}
    setLoading(false);
  }

  return (
    <div className="calendario-section">
      {loading && <Loading />}
      <span className="calendario-mes-nome">
        {monthData.monthData.length
          ? `${monthData.monthData[0].Month.name} ${monthData.monthData[0].year}`
          : "Janeiro 2000"}
      </span>
      <Col className="calendario-container">
        <div className="calendario-dias-semana">
          <span>Dom</span>
          <span>Seg</span>
          <span>Ter</span>
          <span>Qua</span>
          <span>Qui</span>
          <span>Sex</span>
          <span>Sáb</span>
        </div>
        <div className="calendario-semanas-container">
          {daysMon.length ? (
            daysMon.map((thisDay) => (
              <Day dayData={thisDay} key={thisDay.idScheduling} />
            ))
          ) : (
            <EmptyDay />
          )}
        </div>
        <span className="calendario-periodo-legenda">
          M = Período da manhã | T = Período da tarde
        </span>
      </Col>
    </div>
  );
}
