import React, { useEffect, useState } from "react";
import swalError from "common/swalError";

import api from "config/api";

import Calendar from "./Calendar";

import "./styles.scss";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import Page from "components/Page";
import Loading from "components/Loading";

function Scheduling() {
  const [loading, setLoading] = useState(false);

  const [isTab, setIsTab] = useState(true);
  const [isPill, setIsPill] = useState(false);
  const [activeTab, setActiveTab] = useState("mes-atual");
  const [mesAtualActive, setMesAtualActive] = useState(true);
  const [mesPassadoActive, setMesPassadoActive] = useState(false);
  const [mesSeguinteActive, setMesSeguinteActive] = useState(false);

  const [currentMonth, setCurrentMonth] = useState([]);
  const [previousMonth, setPreviousMonth] = useState([]);
  const [nextMonth, setNextMonth] = useState([]);

  useEffect(() => {
    fetchSchedule();
  }, []);

  async function fetchSchedule() {
    setLoading(true);
    try {
      tabOrPill();
      const { data } = await api.get(`/scheduling`);
      setCurrentMonth(data.data.current);
      setPreviousMonth(data.data.previous);
      setNextMonth(data.data.next);
    } catch (error) {
      swalError(error);
    }
    setLoading(false);
  }

  function tabOrPill() {
    const width = window.innerWidth;
    if (width <= 992) {
      setIsPill(true);
      setIsTab(false);
    } else {
      setIsPill(false);
      setIsTab(true);
    }
  }

  function enableAtual() {
    setActiveTab("mes-atual");
    setMesAtualActive(true);
    setMesPassadoActive(false);
    setMesSeguinteActive(false);
  }

  function enablePassado() {
    setActiveTab("mes-passado");
    setMesAtualActive(false);
    setMesPassadoActive(true);
    setMesSeguinteActive(false);
  }

  function enableSeguinte() {
    setActiveTab("mes-seguinte");
    setMesAtualActive(false);
    setMesPassadoActive(false);
    setMesSeguinteActive(true);
  }

  return (
    <Page title="Bem-vindo(a) ao Agendamento de Entregas" loading={loading}>
      {loading && <Loading />}
      <Col>
        <Row>
          <Nav
            tabs={isTab}
            pills={isPill}
            className="mt-4 mb-4 mr-3"
            style={{ width: "100%" }}
          >
            <NavItem>
              <NavLink
                active={mesPassadoActive}
                style={{ cursor: "pointer" }}
                onClick={() => enablePassado()}
                className={`aba-mes-${mesPassadoActive}`}
              >
                {previousMonth.length
                  ? previousMonth[0].Month.name
                  : "Mês anterior"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={mesAtualActive}
                style={{ cursor: "pointer" }}
                onClick={() => enableAtual()}
                className={`aba-mes-${mesAtualActive}`}
              >
                {currentMonth.length ? currentMonth[0].Month.name : "Mês atual"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={mesSeguinteActive}
                style={{ cursor: "pointer" }}
                onClick={() => enableSeguinte()}
                className={`aba-mes-${mesSeguinteActive}`}
              >
                {nextMonth.length ? nextMonth[0].Month.name : "Mês seguinte"}
              </NavLink>
            </NavItem>
          </Nav>
        </Row>
        <Row className="justify-content-center">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="mes-passado">
              <Calendar monthData={previousMonth} />
            </TabPane>
            <TabPane tabId="mes-atual">
              <Calendar monthData={currentMonth} />
            </TabPane>
            <TabPane tabId="mes-seguinte">
              <Calendar monthData={nextMonth} />
            </TabPane>
          </TabContent>
        </Row>
      </Col>
    </Page>
  );
}

export default Scheduling;
