import React from "react";
import { useHistory } from "react-router-dom";

import Loading from 'components/Loading';

import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";

import Header from "components/Header";

export default function NewCampaign({ children, title, classNameCSS, back, loading, }) {
  const history = useHistory();

  return (
    <>
      <Header />
      {loading && <Loading />}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-gradient-secondary shadow">
              <CardHeader className="bg-transparent">
                <h4>{title}</h4>
                {back && (
                  <Col>
                    <Row className="mt-3">
                      <button
                        className="button-like-link"
                        onClick={() => history.goBack()}
                      >
                        <i className="fa fa-arrow-left" /> Voltar
                      </button>
                    </Row>
                  </Col>
                )}
                {children[0]}
              </CardHeader>
              <CardBody className={classNameCSS}>{children[1]}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
