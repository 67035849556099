import UserProfile from "views/admin/UserProfile";
import Scheduling from "views/admin/Scheduling/index.jsx";
import NotFound from "views/admin/NotFound";

var routes = [
  {
    path: "/user-profile",
    name: "Perfil",
    invisible: true,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/index",
    name: "Agendamento",
    icon: "fas fa-calendar-alt",
    component: Scheduling,
    layout: "/admin",
  },
  {
    path: "*",
    invisible: true,
    component: NotFound,
    layout: "/admin",
  },
];
export default routes;
