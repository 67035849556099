import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import swalError from "common/swalError";

import api from "config/api";

import "./styles.scss";

import Loading from "components/Loading";

export default function Day(dayData) {
  const [loading, setLoading] = useState(false);

  const [morningPeriod, setMorningPeriod] = useState(true);
  const [afternoonPeriod, setAfternoonPeriod] = useState(true);
  const [pastDays, setPastDays] = useState("");

  useEffect(() => {
    fetchPeriod(dayData);
  }, [dayData]);

  function fetchPeriod(dayData) {
    setMorningPeriod(dayData.dayData.morning);
    setAfternoonPeriod(dayData.dayData.everning);
    changePastDays(dayData);
  }

  function changePastDays() {
    let dataDate = new Date(
      dayData.dayData.year,
      dayData.dayData.idMonth,
      dayData.dayData.day
    );
    let actualDate = new Date();
    if (actualDate > dataDate) {
      setPastDays("past-day");
      return;
    }
  }

  async function changeMorningPeriod(dayData) {
    setLoading(true);
    let dataDate = new Date(
      dayData.dayData.year,
      dayData.dayData.idMonth,
      dayData.dayData.day,
      23,
      59,
      59
    );
    let actualDate = new Date();
    if (actualDate > dataDate) {
      Swal.fire("Atenção!", "Não é possível alterar datas passadas", "warning");
      setLoading(false);
      return;
    }
    try {
      await api.put(`/scheduling/${dayData.dayData.idScheduling}/morning`, {
        morning: !morningPeriod,
      });
      setMorningPeriod(!morningPeriod);
    } catch (error) {
      swalError(error);
    }
    setLoading(false);
  }

  async function changeAfternoonPeriod(dayData) {
    setLoading(true);
    let dataDate = new Date(
      dayData.dayData.year,
      dayData.dayData.idMonth,
      dayData.dayData.day,
      23,
      59,
      59
    );
    let actualDate = new Date();
    if (actualDate > dataDate) {
      Swal.fire("Atenção!", "Não é possível alterar datas passadas", "warning");
      setLoading(false);
      return;
    }
    try {
      await api.put(`/scheduling/${dayData.dayData.idScheduling}/everning`, {
        everning: !afternoonPeriod,
      });
      setAfternoonPeriod(!afternoonPeriod);
    } catch (error) {
      swalError(error);
    }
    setLoading(false);
  }

  return (
    <div className="dia-container">
      {loading && <Loading />}
      {dayData.dayData.Day !== "empty" ? (
        // Se ambos os os períodos estiverem desativados, terão o valor de false
        // Assim a classe irá se chamar periodo-false-false e receberá a
        // Estilização feita no scss
        <div
          className={`dia-botao periodo-${morningPeriod}-${afternoonPeriod} ${pastDays}`}
        >
          <div className="dia-numero">{dayData.dayData.day}</div>
          <div className="dia-botoes">
            <div
              onClick={() => changeMorningPeriod(dayData)}
              className={`dia-botao-${morningPeriod}`}
            >
              M
            </div>
            <div
              onClick={() => changeAfternoonPeriod(dayData)}
              className={`dia-botao-${afternoonPeriod}`}
            >
              T
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
